@tailwind base;
@tailwind components;
@tailwind utilities;

/* Agrega estilos personalizados para el scrollbar */
/* Ancho del scrollbar */
::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Estilos para el track del scrollbar */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de fondo */
  }
  
  /* Estilos para el thumb (el indicador de posición) */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 0px; /* Radio de borde */
  }
  
  /* Estilos para el thumb cuando se desplaza */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del thumb cuando se desplaza */
  }

  .animate-floating {
    animation: floating 6s ease-in-out infinite;
}

@keyframes floating {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
  